export const MANAGER_MODIFIED = "MANAGER_MODIFIED"
export const ESSENTIAL = "ESSENTIAL"
export const BASIC = "BASIC"
export const FREE = "FREE"
export const PRO = "PRO"
export const ADVANTAGE_PRO = "ADVANTAGE PRO"
export const ONE_DAY_BASIC = "ONEDAYBASIC"
export const ONE_DAY_PRO = "ONEDAYPRO"
export const Essential = "Essential"
export const Basic = "Basic"
export const Pro = "Pro"
export const notApplicable = "N/A"
export const noDataFound = "No Data Found"
export const False = "False"
export const True = "True"
export const Inactive = "Inactive"
export const Active = "Active"
export const Manager = "Manager"
export const EventsString = "Events"
export const success = "success"
export const Completed = "Completed"
export const typeString = "string"
export const typeNumber = "number"

export const editType = {
  deleteShop: "deleteshop",
  deleteUser: "deleteuser",
  disable: "disable",
  callSplit: "callsplit"
}


export const searchType = {
  city: "city",
  hotel: "hotel",
  compet: "compet"
}

export const reviewTitle = {
  overallReviews: "Overall Reviews",
  cleanliness: "Cleanliness",
  service: "Service",
  location: "Location",
  comfort: "Comfort",
  Facility: "Facility",
  valueForMoney: "Value For Money"
}
export const size = {
  large: "Large",
  medium: "Medium",
  small: "Small",
}

export const packagePlanTypes = {
  free: "free",
  basic: "basic",
  pro: "pro",
  essential: "essential",
  advantagePro: "advantagePro",
  oneDayPro: "oneDayPro",
  oneDayBasic: "oneDayBasic"
}
export const ratesFilterContants = [
  "Lowest",
  "All Channels",
  "1 Night",
  "2 Guests",
  "Any Room",
  "Any Meal",
  "Competitor Set",
  "Rate Plan",
];

export const initialFilterDataValues = {
  anyNumberofGuests: "Any Number of Guests",
  anyMealPlan: "Any Meal Plan",
  anyLengthofStay: "Any Length of Stay",
  anyChannel: "Any Channel",
  anyCancellationPolicy: "Any Cancellation Policy",
  anyRoomType: "Any Room Type",
  anySource:"Any Source"
}

export const packageDetailsNumber = ["4","8"];

export const editMessage = {
  accountDisabled: "Account Disabled!",
  accountActivated: "Account Activated!",
  rateshopDeletedSuccessfully: "Rateshop deleted successfully!",
  accountDeletedSuccessfully: "Account deleted successfully!",
  accountRefreshedSuccessfully: "Account refreshed successfully!",
}

export const horizonData = { hor30: 30, hor60: 60, hor90: 90, hor365: 365 }
export const horizonDataDays ={hor30:"30 days horizon",hor60:"60 days horizon",hor90:"90 days horizon",hor365:"365 days horizon"}

export const tableNameConst = ["WithShop","EventShops"]

export const  packagePrice = ["$5","$10","$19","$29"]

export const filterFieldConstData = {
  los: "los",
  maxoccupancy: "maxoccupancy",
  mealinclusiontype: "mealinclusiontype",
  rateVolume: "rateVolume",
  ratetype: "ratetype",
  roomtype: "roomtype",
  source: "source"
}

export const myPricePosition = "My Price Position";
export const numberOfReviews = "Number Of Reviews";
export const filterNoRecore = "No Records found for this filter.";
export const paymentSuccess = "Successfully Updated Payment";
export const currencyConstant = "currency";
export const filterDatas = ["Impact_EventType", "category", "expectedAttendees", "expectedattendees", "impact", "location", "proximity", "radius", "subCategory", "subcategory", "venue"]
export const proxi = ["2", "10", "50"];
export const maxCompetitors = ["8 competitors", "8 competitors", "10 competitors"]
export const Valid = "Valid";
export const Invalid = "Invalid";
//Staging site
// export const loadEvent = "/apieventimpactfetch_staging";
//Live site
export const loadEvent = "/apieventimpactfetch";
export const loadAllEvent = "/apieventimpactfetchall"

export const adminString = "Admin";
export const category1 = "Community/Race Events";
export const category2 = "Entertainment events";
export const category3 = "Holidays, Festivals and Celebrations";
export const category4 = "Sports events";
export const category5 = "Tradeshows, Business events and conferences";

export const tokenApiCredentials = {
  username: "newuser@picasso.com",
  password: "newuser"
}

export const filterConstants = {
  rateType: "", //Flexible cancellation
  mealInclusion: "Breakfast Included",
  occupancy: 2,
  rateVolume: {
    min_price: "min_price",
    max_price: "max_price",
    median_price: "median_price",
    minRate: "Min rate",
    medRate: "Med rate",
    maxRate: "Max rate"
  },
}

export const createManagerFormConstants = {
  FILE: "file",
  LOGO: "Logo",
  EMAIL: "email",
  EMAIL_LABEL: `Manager Email *`,
  LOGO_LABEL: "Manager Logo *",
  emailRules: { required: true, pattern: { value: /^\S+@\S+\.\S+$/, message: "Invalid email" } },
  logoRules: { required: true },
}

export const CREATE_MANAGER_FORM_EMAIL_FIELD_PROPS = {
  type: createManagerFormConstants.EMAIL,
  name: createManagerFormConstants.EMAIL,
  label: createManagerFormConstants.EMAIL_LABEL,
  rules: createManagerFormConstants.emailRules
}
export const CREATE_MANAGER_FORM_LOGO_FIELD_PROPS = {
  type: createManagerFormConstants.FILE,
  name: createManagerFormConstants.LOGO,
  label: createManagerFormConstants.LOGO_LABEL,
  rules: createManagerFormConstants.logoRules
}

export const subscriptionId = {
  free: 1,
  basic: 2,
  pro: 3,
  manager: 4
}

export const eventSubscriptionId = {
  free: 11,
  basic: 12,
  pro: 13,
  manager: 14
}

export const modifyFormConstants = {
  TEXT: "text",
  COLOR: "color",
  NUMBER: "number",
  SELECT: "select",
  FREQUENCY: "frequency",

  EMAIL: "email",
  PRIMARY_COLOR: "primarycolor",
  SECONDARY_COLOR: "secondarycolor",
  COMPETITORS: "competitors",
  LOS: "los",
  CHANNEL: "channel",
  DAYSOUT: "daysout",
  INT_INPUT_1: "intInput1",
  INT_INPUT_2: "intInput2",
  INT_INPUT_3: "intInput3",
  FREQ_INPUT_1: "freqinput1",
  FREQ_INPUT_2: "freqinput2",
  FREQ_INPUT_3: "freqinput3",
  REFRESH: "refresh",
  PRICES: "prices",


  EMAIL_LABEL: "Email Address",
  PRIMARY_COLOR_LABEL: "Primary Colour",
  SECONDARY_COLOR_LABEL: "Secondary Colour",
  COMPETITORS_LABEL: "Max Competitors",
  LOS_LABEL: "Max LOS",
  CHANNEL_LABEL: "Max Channels",
  DAYSOUT_LABEL: "Total Days out",
  FREQUENCY_LABEL: "Scheduling frequency per interval",
  REFRESH_LABEL: "Max Real Time Refreshes Per day",
  PRICES_LABEL: "Price Per Hotel ($)",
  INTERVAL_ERROR_MESSAGE: "must be greater than previous",

  emailRegex: /^\S+@\S+\.\S+$/,
  int1Regex: /^-\d+$/,
  int2Regex: /^-\d+$/,
  int3Regex: /^-\d+$/,

  // emailRules: { required: true ,pattern: { value: /^\S+@\S+\.\S+$/, message: "Invalid email" }},
  emailRules: { required: true },
  primaryColorRules: { required: true },
  secondaryColorRules: { required: true },
  competitorsRules: { required: true },
  losRules: { required: true },
  channelRules: { required: true },
  daysOutRules: { required: true },
  // int1Rules: { required: true, pattern: { value: /^-\d+$/, message: "Negative numbers are not accepted" } },
  // int2Rules: { required: true, pattern: { value: /^-\d+$/, message: "Negative numbers are not accepted" } },
  // int3Rules: { required: true, pattern: { value: /^-\d+$/, message: "Negative numbers are not accepted" } },
  int1Rules: { required: true },
  int2Rules: { required: true },
  int3Rules: { required: true },
  freq1Rules: { required: true },
  freq2Rules: { required: true },
  freq3Rules: { required: true },
  refreshRules: { required: true },
  pricesRules: { required: true },


  CompetitorsSelectOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  losSelectOptions: [1, 2, 3, 4, 5],
  sourceSelectOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
  refreshSelectOptions: [1, 2, 3, 4, 5],
  frequencySelectOptions: ["Daily", "Weekly", "Monthly"],
  daysoutSelectOptions: [30, 60, 90, 120, 180, 365],


}
export const MODIFY_FORM_EMAIL_FIELD_PROPS = {
  type: modifyFormConstants.TEXT,
  name: modifyFormConstants.EMAIL,
  label: "",
  rules: modifyFormConstants.emailRules
}
export const PRIMARY_COLOR_FIELD_PROPS = {
  type: modifyFormConstants.COLOR,
  name: modifyFormConstants.PRIMARY_COLOR,
  label: "",
  rules: modifyFormConstants.primaryColorRules
}
export const SECONDARY_COLOR_FIELD_PROPS = {
  type: modifyFormConstants.COLOR,
  name: modifyFormConstants.SECONDARY_COLOR,
  label: "",
  rules: modifyFormConstants.secondaryColorRules
}
export const COMPETITORS_FIELD_PROPS = {
  type: modifyFormConstants.SELECT,
  name: modifyFormConstants.COMPETITORS,
  label: "",
  rules: modifyFormConstants.competitorsRules,
  options: modifyFormConstants.CompetitorsSelectOptions
}
export const LOS_FIELD_PROPS = {
  type: modifyFormConstants.SELECT,
  name: modifyFormConstants.LOS,
  label: "",
  rules: modifyFormConstants.losRules,
  options: modifyFormConstants.losSelectOptions
}
export const CHANNEL_FIELD_PROPS = {
  type: modifyFormConstants.SELECT,
  name: modifyFormConstants.CHANNEL,
  label: "",
  rules: modifyFormConstants.channelRules,
  options: modifyFormConstants.sourceSelectOptions
}
export const DAYSOUT_FIELD_PROPS = {
  type: modifyFormConstants.SELECT,
  name: modifyFormConstants.DAYSOUT,
  label: "",
  rules: modifyFormConstants.daysOutRules,
  options: modifyFormConstants.daysoutSelectOptions
}
export const INT_INPUT_FIELD_1_PROPS = {
  type: modifyFormConstants.NUMBER,
  name: modifyFormConstants.INT_INPUT_1,
  label: "",
  // rules: modifyFormConstants.int1Rules,
  options: modifyFormConstants.frequencySelectOptions

}
export const INT_INPUT_FIELD_2_PROPS = {
  type: modifyFormConstants.NUMBER,
  name: modifyFormConstants.INT_INPUT_2,
  label: "",
  // rules: modifyFormConstants.int2Rules,
  options: modifyFormConstants.frequencySelectOptions

}
export const INT_INPUT_FIELD_3_PROPS = {
  type: modifyFormConstants.NUMBER,
  name: modifyFormConstants.INT_INPUT_3,
  label: '',
  // rules: modifyFormConstants.int3Rules,
  options: modifyFormConstants.frequencySelectOptions

}
export const FREQ_INPUT_1_FIELD_PROPS = {
  type: modifyFormConstants.SELECT,
  name: modifyFormConstants.FREQ_INPUT_1,
  label: "",
  rules: modifyFormConstants.freq1Rules,
  options: modifyFormConstants.frequencySelectOptions

}
export const FREQ_INPUT_2_FIELD_PROPS = {
  type: modifyFormConstants.SELECT,
  name: modifyFormConstants.FREQ_INPUT_2,
  label: "",
  rules: modifyFormConstants.freq2Rules,
  options: modifyFormConstants.frequencySelectOptions
}
export const FREQ_INPUT_3_FIELD_PROPS = {
  type: modifyFormConstants.SELECT,
  name: modifyFormConstants.FREQ_INPUT_3,
  label: "",
  rules: modifyFormConstants.freq3Rules,
  options: modifyFormConstants.frequencySelectOptions
}
export const REFRESH_FIELD_PROPS = {
  type: modifyFormConstants.SELECT,
  name: modifyFormConstants.REFRESH,
  label: "",
  rules: modifyFormConstants.refreshRules,
  options: modifyFormConstants.refreshSelectOptions

}
export const PRICES_FIELD_PROPS = {
  type: modifyFormConstants.NUMBER,
  name: modifyFormConstants.PRICES,
  label: "",
  rules: modifyFormConstants.pricesRules
}

export const priceString = {
  BASIC: "39",
  PRO: "99",
}

export const eventPriceString = {
  BASIC: "39",
  PRO: "99",
}

export const priceNumber = {
  BASIC: 39,
  PRO: 99,
}

export const eventPriceNumber = {
  BASIC: 39,
  PRO: 99,
}

export const promoCodeFormConstants = {
  // string constants
  TEXT: "text",
  RADIO: "radio",
  NUMBER: "number",
  SELECT: "select",
  PROMOCODE: "code",
  PLAN_TYPE: "planType",
  DISCOUNT: "discount",
  MONTH_DURARTION: "monthDuration",
  PROMO_CODE_LABEL: "Promo Code",
  PLAN_TYPE_LABEL: "Plan Type",
  DISCOUNT_LABEL: "Discount",
  MONTH_DURATION_LABEL: "Month Duration",
  DISABLED_MESSAGE: "Field cannot be edited!",
  BUTTON_GENERATE: "Generate Promo Code",
  BUTTON_UPDATE: "Update Promo Code",
  USER_DETAILS: "userDetails",
  PLAN_TYPE_BASIC: "BASIC",

  // constants for mapping fields
  radioOptions: [[2, "Essential"], [3, "Advantage Pro"]],
  selectOptions: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],

  // constants for validation 
  discountRegex: /^([0-9]|[1-9][0-9]|100)$/,

  promocodeRegex: /^[A-Z][A-Z0-9]{5,12}$/,
  discountValidationMessage: "Discount must be less than or equal to 100",
  promocodeValidationMessage: "UpperCase or uppercase + number and 6-12 charecters required",

  // alert constants
  alertMessage: "PROMO CODE already exists",
  // redux constants
  data_param: {
    "id": "",
    "code": "",
    "discount": "",
    "monthduration": "",
    "subscriptionplan": "",
    "modifytype": "Select",
    //hook form constants
    reset: {
      code: "",
      discount: "",
      monthDuration: "",
      planType: ""
    }
  },
  //validation constants
  promoCodeRules: { required: true, pattern: { value: /^[A-Z][A-Z0-9]{5,12}$/, message: "Uppercase or uppercase + number and 6-12 charecters required" } },
  planTypeRules: { required: true },
  discountRules: { required: true, pattern: { value: /^([0-9]|[1-9][0-9]|100)$/, message: "Discount must be less than or equal to 100" } },
  monthDurationRules: { required: true }


}

export const PROMO_CODE_INPUT_FIELD_PROPS = {
  type: promoCodeFormConstants.TEXT,
  name: promoCodeFormConstants.PROMOCODE,
  label: promoCodeFormConstants.PROMO_CODE_LABEL,
  rules: promoCodeFormConstants.promoCodeRules
}
export const PLAN_TYPE_INPUT_FIELD_PROPS = {
  type: promoCodeFormConstants.RADIO,
  name: promoCodeFormConstants.PLAN_TYPE,
  label: promoCodeFormConstants.PLAN_TYPE_LABEL,
  rules: promoCodeFormConstants.planTypeRules,
  options: promoCodeFormConstants.radioOptions

}
export const DISCOUNT_INPUT_FIELD_PROPS = {
  type: promoCodeFormConstants.NUMBER,
  name: promoCodeFormConstants.DISCOUNT,
  label: promoCodeFormConstants.DISCOUNT_LABEL,
  rules: promoCodeFormConstants.discountRules,

}
export const MONTH_DURARTION_INPUT_FIELD_PROPS = {
  type: promoCodeFormConstants.SELECT,
  name: promoCodeFormConstants.MONTH_DURARTION,
  label: promoCodeFormConstants.MONTH_DURATION_LABEL,
  rules: promoCodeFormConstants.monthDurationRules,
  options: promoCodeFormConstants.selectOptions

}

// export const packageDetailsConstants = [
//   ["30 days horizon", "1 channel", "1 LOS", "4 competitors", "1 real time refresh / day", "30 days out"],
//   ["365 days horizon", "6 channels", "2 LOS", "8 competitors", "3 real time refresh / day", "60 days out"],
//   ["365 days horizon", "8 channels", "2 LOS", "10 competitors", "4 real time refresh / day", "90 days out"]
// ];
export const packageDetailsConstants = [
  ["30 days horizon", "1 channel", "1 LOS", "8 competitors", "1 real time refresh / day"],
  ["60 days horizon", "6 channels", "2 LOS", "8 competitors", "3 real time refresh / day"],
  ["90 days horizon", "8 channels", "2 LOS", "10 competitors", "4 real time refresh / day"]
];
export const packageDaysout = [30, 60, 90];
export const competitorsCount = [8, 8, 10];
export const packageTypeConstants = ["FREE", "BASIC", "PRO"];
export const packageNewTypesContants = ["FREE", "ESSENTIAL", "ADVANTAGE PRO", "MY PLAN"];

export const ratesCalenderHeadersMon = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
export const ratesCalenderHeadersSun = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
export const ratesCalenderHeadersSat = ["SAT", "SUN", "MON", "TUE", "WED", "THU", "FRI"];


export const marketReviews = {
  hotelCodes: [
    2352725,
    196078,
    196081,
    36402,
    721305,
    1430159,
    1432138,
    517468,
  ],
  sourceCodes: [2],
  limit: 5000,
};

export const ratesByDayHeader = ["Rank", "Hotel", "Rate"];
export const distance = ["Small Radius", "Medium Radius", "Large Radius"];
export const holidaycategory = "Holidays, Festivals and Celebrations";
export const holidaycategoryUI = "Festivals and Celebrations";
export const category = [
  { "category": "Community/Race Events", "id": 5, "subCategory": ["Charity & Causes", "Community", "Community Party", "Food Festival", "Government", "Marathon", "Parade", "Political & Social", "Promotional", "Spirituality", "Triathlon"] },
  { "category": "Entertainment events", "id": 1, "subCategory": ["Amusement", "Arts", "Attractions", "Comedy", "Concerts", "Clubs", "Dance", "Days Out", "Family & Kids", "Film & Media", "Folkloric", "Theater", "Tourism", "Visual Art"] },
  { "category": "Festivals and Celebrations", "id": 2, "subCategory": ["Celebration", "Festival", "Historic", "Local Holiday", "Religious", "Religious Holiday", "State Holiday"] },
  { "category": "Sports events", "id": 3, "subCategory": ["American Football", "Arts", "Auto Racing", "Baseball", "Basketball", "Boxing", "Cricket", "Cycling", "Golf", "Hockey", "Ice Hockey", "MLB", "MLS", "NBA", "NFL", "NHL", "Rugby", "Soccer/Football", "Sports", "Tennis", "Volleyball", "Wrestling"] },
  { "category": "Tradeshows, Business events and conferences", "id": 4, "subCategory": ["Academic", "Business & Tech", "Conferences", "Fashion", "Health & Wellness", "Lectures & Talks", "Meetups", "Seminar & Classes", "Tradeshows/Fairs/Expo", "Workshops"] },
];
export const expectedAttendees = ["<100", "<500", "<1000", "<5000", "<10000", "10000+", ">10000"];
export const impact = ["Very Low", "Low", "Medium", "High", "Very High"];

export const expedia = ["Booking.com", "MakeMyTrip", "Agoda", "Goibibo", "Hotels.com", "Yatra", "Google", "AirBnb", "TripAdvisor", "Facebook"];
export const row = [10, 25, 100];

// Select Competitor All title Tooltip content

export const comptooltipcontent = {
  yourhotel: "Find your property by typing the name of the corresponding listing on Booking.com.",
  competitor: "Search the surrounding properties that might be a competitor for you: our suggestions include the ones nearby that have the same star level.",
  currency: "Select the desired currency.",
  pos: "Select the desired point of sale.",
  los: "Select the length of stay for the prices you want to analyze.",
  ota: "Pick the Online Travel Agencies (OTAs) from which you want the prices to come up.",
}
export const mydashboard = "Explore your features dashboards - rates, reviews, and events";
export const dwnlview = "Download the view";
export const goToEvent = "See events on the map";
export const ratestooltipcontent = {
  filter: [
    "OTA",
    "Length Of Stay",
    "Number of Guests",
    "Meal Plan",
    "Cancelation Policy",
    "Room Type",
    "Rate (min, med, max)",
  ],
  ratescal: "Rates Calendar View",
  ratesevl: "Rates evolution",
  rateslst: "Rates list View",
  ratesparity: "Rate Parity",
}
export const reviewstooltipcontent = {
  overview: "Quick reviews Overview",
  reviewsgrph: "Reviews graphs",
  reviewslits: "Reviews table view"
}

export const eventstooltipcontent = {
  filter: {
    location: "Locations",
    radius: "Radius",
    category: "Events Categories",
    subcategory: "Events subcategories",
    attendees: "Expected Attendees",
  },
  eventcal: "Events Calendar View",
  eventgrph: "Events graphs",
  eventlst: "Events List View",
}

export const ratesskipdata = ["rates-1", "rates-2", "rates-3", "rates-4", "rates-5", "rates-6", "rates-7"]

export const steps = [
  {
    selector: '[data-tut="step-1"]',
    content: '1/1 Find your property by typing the name of the corresponding listing on Booking.com. Search the surrounding properties that might be a competitor for you: our suggestions include the ones nearby that have the same star level.',
  },
]

export const steperSteps = [
  {
    selector: '[data-tut="step-1"]',
    content: '1/2 Select the desired currency and POS for your rates. Select the length of stay for the prices you want to analyze. Pick the Online Travel Agencies (OTAs) from which you want the prices to come up.',
  },
  {
    selector: '[data-tut="step-3"]',
    content: '2/2 This is what you picked. Once you finalize your setup, we will still support you if you’ll need to change or update it.',
  },
]

export const otaStepDescriptions = {
  otafree: "Choose your preferred OTA*. Your plan currently includes only one. Upgrade to get more",
  otaessential: "Choose your preferred OTA*. Your plan currently includes 6 OTAs. Upgrade to get more",
  otapro: "Choose your preferred OTA*. Your plan currently includes 8 OTAs.",
  otamanagerpanel: "Choose your preferred OTA*"
}

export const losStepDescriptions = {
  lostxt: "Your plan currently includes",
  losfree: "only one",
  losessential: "2 Los",
  lospro: "2 Los",
  losUpgradetxt: "Upgrade to get more",
}

export const gueststep = {
  selector: '[data-tut="rates-3"]',
  content: (() => {
    return (
      <div>
        <h5>Number of Guests</h5>
        <p>Choose your preferred number of guests</p>
      </div>
    )
  })
}

export const mealpalnstep = {
  selector: '[data-tut="rates-4"]',
  content: (() => {
    return (
      <div>
        <h5>Meal Plan</h5>
        <p>Choose your preferred meal plan</p>
      </div>
    )
  })
}

export const cancelationstep = {
  selector: '[data-tut="rates-5"]',
  content: (() => {
    return (
      <div>
        <h5>Cancelation Policy</h5>
        <p>Choose your preferred cancelation policy</p>
      </div>
    )
  })
}

export const roomtypestep = {
  selector: '[data-tut="rates-6"]',
  content: (() => {
    return (
      <div>
        <h5>Room Type</h5>
        <p>Choose your preferred room type</p>
      </div>
    )
  })
}

export const ratestep = {
  selector: '[data-tut="rates-7"]',
  content: (() => {
    return (
      <div>
        <h5>Rate</h5>
        <p>Choose your preferred rate(min, med,  max)</p>
      </div>
    )
  })
}

export const ratescalenderstep = {
  selector: '[data-tut="rates-8"]',
  content: (() => {
    return (
      <div>
        <h5>Rates Calendar View</h5>
        <p>Traffic light color coding lets you see how your rates compare</p>
      </div>
    )
  })
}

export const ratesevalutionstep = {
  selector: '[data-tut="rates-9"]',
  content: (() => {
    return (
      <div>
        <h5>Rates evolution</h5>
        <p>See room rate highlight over a set time, including which competitor had the highest and lowest rates, how their and your rates evolved</p>
      </div>
    )
  })
}

export const rateslistviewstep = {
  selector: '[data-tut="rates-10"]',
  content: (() => {
    return (
      <div>
        <h5>Rates list View</h5>
        <p>Table comparison of your rates versus your competitors</p>
      </div>
    )
  })
}

export const rateparitystep = {
  selector: '[data-tut="rates-11"]',
  content: (() => {
    return (
      <div>
        <h5>Rate Parity</h5>
        <p>Instances of disparity are tracked and visualized, making it simple to indentify issues and take action to rectify them quickly</p>
      </div>
    )
  })
}

export const downloadviewstep = {
  selector: '[data-tut="rates-12"]',
  content: (() => {
    return (
      <div>
        <h5>Download the view</h5>
        <p>Download your dashboard view in PDF format file</p>
      </div>
    )
  })
}

// export const ratessteps = [otastep, losstep, gueststep, mealpalnstep, cancelationstep, roomtypestep, ratestep, ratescalenderstep, ratesevalutionstep, rateslistviewstep, rateparitystep, downloadviewstep]

export const reviewssteps = [
  // ...Rates
  {
    selector: '[data-tut="reviews-1"]',
    content: (() => {
      return (
        <div>
          <h5>Quick reviews Overview</h5>
          <p>Highlights of your hotels reviews ranking among your competitors</p>
        </div>
      )
    })
  },
  {
    selector: '[data-tut="reviews-2"]',
    content: (() => {
      return (
        <div>
          <h5>Reviews graphs</h5>
          <p>Number of reviews graphics, reflecting number of reviews overall reviews rank cleanliness, comfort, facility, location, staff score and value for money scores</p>
        </div>
      )
    })
  },
  {
    selector: '[data-tut="reviews-3"]',
    content: (() => {
      return (
        <div>
          <h5>Reviews table view</h5>
          <p>Table comparision of each rank of your competitor against your, which color indicators</p>
        </div>
      )
    })
  },
  {
    selector: '[data-tut="reviews-4"]',
    content: (() => {
      return (
        <div>
          <h5>Download the view</h5>
          <p>Download your dashboard view in PDF format file</p>
        </div>
      )
    })
  },
]

// export const eventssteps = [
//   // ...Rates
//   {
//     selector: '[data-tut="events-1"]',
//     content: (() => {
//       return (
//         <div>
//           <h5>Radius</h5>
//           <p>Choose your preferred distance radius from your hotel</p>
//         </div>
//       )
//     })
//   },
//   {
//     selector: '[data-tut="events-2"]',
//     content: (() => {
//       return (
//         <div>
//           <h5>Events Categories</h5>
//           <p>Choose your preferred events category</p>
//         </div>
//       )
//     })
//   },
//   {
//     selector: '[data-tut="events-3"]',
//     content: (() => {
//       return (
//         <div>
//           <h5>Events subcategories</h5>
//           <p>Choose your preferred events subcategory</p>
//         </div>
//       )
//     })
//   },
//   {
//     selector: '[data-tut="events-4"]',
//     content: (() => {
//       return (
//         <div>
//           <h5>Expected Attendees</h5>
//           <p>Choose your preferred number of event attendees</p>
//         </div>
//       )
//     })
//   },
//   {
//     selector: '[data-tut="events-5"]',
//     content: (() => {
//       return (
//         <div>
//           <h5>Events Calendar View</h5>
//           <p>A Calendar with color coded categories of events</p>
//         </div>
//       )
//     })
//   },
//   {
//     selector: '[data-tut="events-6"]',
//     content: (() => {
//       return (
//         <div>
//           <h5>Events graphs</h5>
//           <p>Events data represented in graphs, reflecting expected attendees and events per day</p>
//         </div>
//       )
//     })
//   },
//   {
//     selector: '[data-tut="events-7"]',
//     content: (() => {
//       return (
//         <div>
//           <h5>Events List View</h5>
//           <p>Complete list of events sorted by date</p>
//         </div>
//       )
//     })
//   },
//   {
//     selector: '[data-tut="events-8"]',
//     content: (() => {
//       return (
//         <div>
//           <h5>Download the view</h5>
//           <p>Download your dashboard view in PDF format file</p>
//         </div>
//       )
//     })
//   },
// ]

export const eventsonlysteps = [
  // ...Rates
  {
    selector: '[data-tut="events-0"]',
    content: (() => {
      return (
        <div>
          <h5>Location</h5>
          <p>Choose your preferred Location.</p>
        </div>
      )
    })
  },
  {
    selector: '[data-tut="events-1"]',
    content: (() => {
      return (
        <div>
          <h5>Radius</h5>
          <p>Choose your preferred distance radius from your hotel</p>
        </div>
      )
    })
  },
  {
    selector: '[data-tut="events-2"]',
    content: (() => {
      return (
        <div>
          <h5>Events Categories</h5>
          <p>Choose your preferred events category</p>
        </div>
      )
    })
  },
  {
    selector: '[data-tut="events-3"]',
    content: (() => {
      return (
        <div>
          <h5>Events subcategories</h5>
          <p>Choose your preferred events subcategory</p>
        </div>
      )
    })
  },
  {
    selector: '[data-tut="events-4"]',
    content: (() => {
      return (
        <div>
          <h5>Expected Attendees</h5>
          <p>Choose your preferred number of event attendees</p>
        </div>
      )
    })
  },
  {
    selector: '[data-tut="events-5"]',
    content: (() => {
      return (
        <div>
          <h5>Events Calendar View</h5>
          <p>A Calendar with color coded categories of events</p>
        </div>
      )
    })
  },
  {
    selector: '[data-tut="events-6"]',
    content: (() => {
      return (
        <div>
          <h5>Events graphs</h5>
          <p>Events data represented in graphs, reflecting expected attendees and events per day</p>
        </div>
      )
    })
  },
  {
    selector: '[data-tut="events-7"]',
    content: (() => {
      return (
        <div>
          <h5>Events List View</h5>
          <p>Complete list of events sorted by date</p>
        </div>
      )
    })
  },
  {
    selector: '[data-tut="events-8"]',
    content: (() => {
      return (
        <div>
          <h5>Download the view</h5>
          <p>Download your dashboard view in PDF format file</p>
        </div>
      )
    })
  },
]
export const faFacebookF = <svg xmlns="http://www.w3.org/2000/svg" className="fill-wt" height="1em" viewBox="0 0 320 512"><path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" /></svg>
export const faLinkedinIn = <svg xmlns="http://www.w3.org/2000/svg" className="fill-wt" height="1em" viewBox="0 0 448 512"><path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" /></svg>
export const SEO = {
  // SEO
  PAGE_TITLE: '',
  PAGE_DESCRIPTION: '',
  DOMAIN: "https://hotel.ratemetrics.com",
  MAIN_KEYWORDS: "hospitality industry, hospitality revenue management, hotel revenue, hotel revenue management software, hotel yield management software, hotel revenue management system, hotel market data, hotel room revenue, best hotel price comparison site, hotel pricing strategies, hospitality data, Hotel revenue management, hotel rate shopping tool, revenue management strategies, pricing strategies in hotel industry, hotel demand, rate shopper, hospitality industry data, hospitality industry statistics, hospitality pricing strategies, hotel dynamic pricing, big data in hospitality industry, hotel business analytics, hotel revenue management solutions, hotel revenue management strategies, rateshopper, dynamic pricing in hospitality industry, rate shopper software, rate shopper for hotels, Hotel rate shopping, hospitality demand forecasting, Hospitality data mining, Hospitality data preparation, Hospitality pricing data, Hospitality pricing data visualization, hospitality business analytics, Ratemetrics, best room pricing strategy, hotel rates benchmark, hotel rates benchmarking, room rate pricing strategy, room rate strategy, ratemetrics", //most searched or important words in websit
  DEFAULT_TITLE: "Ratemetrics: Leading hotel revenue management solutions",
  DEFAULT_DESCRIPTION: "Ratemrtrics is a leading hotel revenue intelligence. It is a one stop solution for hotel revenue management solutions to make smarter & faster pricing decisions",
}

export const currencyCodeVal =  [
  {
    "currencycode": "AED",
    "currencyname": "U.A.E. Dirham"
},
{
    "currencycode": "ARS",
    "currencyname": "Argentine Peso"
},
{
    "currencycode": "AUD",
    "currencyname": "Australian Dollar"
},
{
    "currencycode": "AZN",
    "currencyname": "Azerbaijani New Manats"
},
{
    "currencycode": "BGN",
    "currencyname": "Bulgarian Lev"
},
{
    "currencycode": "BHD",
    "currencyname": "Bahrain Dinar"
},
{
    "currencycode": "BRL",
    "currencyname": "Brazilian Real"
},
{
    "currencycode": "CAD",
    "currencyname": "Canadian Dollar"
},
{
    "currencycode": "CHF",
    "currencyname": "Swiss Franc"
},
{
    "currencycode": "CLP",
    "currencyname": "Chilean Peso"
},
{
    "currencycode": "CNY",
    "currencyname": "Chinese Yuan"
},
{
    "currencycode": "COP",
    "currencyname": "Colombian Peso"
},
{
    "currencycode": "CZK",
    "currencyname": "Czech Koruna"
},
{
    "currencycode": "DKK",
    "currencyname": "Danish Krone"
},
{
    "currencycode": "EGP",
    "currencyname": "Egyptian Pound"
},
{
    "currencycode": "EUR",
    "currencyname": "Euro"
},
{
    "currencycode": "FJD",
    "currencyname": "Fijian Dollar"
},
{
    "currencycode": "GBP",
    "currencyname": "Pound Sterling"
},
{
    "currencycode": "GEL",
    "currencyname": "Georgian Lari"
},
{
    "currencycode": "HKD",
    "currencyname": "Hong Kong Dollar"
},
{
    "currencycode": "HUF",
    "currencyname": "Hungarian Forint"
},
{
    "currencycode": "IDR",
    "currencyname": "Indonesian Rupiah"
},
{
    "currencycode": "ILS",
    "currencyname": "New Israeli Sheqel"
},
{
    "currencycode": "INR",
    "currencyname": "Indian Rupee"
},
{
    "currencycode": "JOD",
    "currencyname": "Jordanian Dinar"
},
{
    "currencycode": "JPY",
    "currencyname": "Japanese Yen"
},
{
    "currencycode": "KRW",
    "currencyname": "Korean Won"
},
{
    "currencycode": "KWD",
    "currencyname": "Kuwaiti Dinar"
},
{
    "currencycode": "KZT",
    "currencyname": "Kazakhstani Tenge"
},
{
    "currencycode": "MDL",
    "currencyname": "Moldovan Leu"
},
{
    "currencycode": "MXN",
    "currencyname": "Mexican Peso"
},
{
    "currencycode": "MYR",
    "currencyname": "Malaysian Ringgit"
},
{
    "currencycode": "NAD",
    "currencyname": "Namibian Dollar"
},
{
    "currencycode": "NOK",
    "currencyname": "Norwegian Krone"
},
{
    "currencycode": "NZD",
    "currencyname": "New Zealand Dollar"
},
{
    "currencycode": "OMR",
    "currencyname": "Omani Rial"
},
{
    "currencycode": "PHP",
    "currencyname": "Philippine Peso"
},
{
    "currencycode": "PLN",
    "currencyname": "Polish Zloty"
},
{
    "currencycode": "QAR",
    "currencyname": "Qatari Riyal"
},
{
    "currencycode": "RON",
    "currencyname": "Romanian New Leu"
},
{
    "currencycode": "RUB",
    "currencyname": "Russian Ruble"
},
{
    "currencycode": "SAR",
    "currencyname": "Saudi Arabian Riyal"
},
{
    "currencycode": "SEK",
    "currencyname": "Swedish Krona"
},
{
    "currencycode": "SGD",
    "currencyname": "Singaporean Dollar"
},
{
    "currencycode": "THB",
    "currencyname": "Thai Baht"
},
{
    "currencycode": "TRY",
    "currencyname": "Turkish Lira"
},
{
    "currencycode": "TWD",
    "currencyname": "New Taiwan Dollar"
},
{
    "currencycode": "UAH",
    "currencyname": "Ukraine Hryvnia"
},
{
    "currencycode": "USD",
    "currencyname": "U.S. Dollar"
},
{
    "currencycode": "VND",
    "currencyname": "Vietnamese dong"
},
{
    "currencycode": "XOF",
    "currencyname": "CFA Franc BCEAO"
},
{
    "currencycode": "ZAR",
    "currencyname": "South African Rand"
}
]

export const posValue = [
  {
    "pos": 0,
    "region": "Americas"
  },
  {
    "pos": 1,
    "region": "IN"
  },
  {
    "pos": 2,
    "region": "ES"
  }
] 

// Function to fetch currency symbols dynamically based on provided data
 export const allCurrencies = {
      "PHP": "₱",
      "AED": "د.إ",
      "AFN": "؋",
      "ALL": "L",
      "AMD": "֏",
      "ANG": "ƒ",
      "AOA": "Kz",
      "ARS": "$",
      "AUD": "$",
      "AWG": "ƒ",
      "AZN": "₼",
      "BAM": "КМ",
      "BBD": "$",
      "BDT": "৳",
      "BGN": "лв",
      "BHD": ".د.ب",
      "BIF": "FBu",
      "BMD": "$",
      "BND": "$",
      "BOB": "Bs.",
      "BRL": "R$",
      "BSD": "$",
      "BTN": "Nu.",
      "BWP": "P",
      "BYN": "Br",
      "BZD": "BZ$",
      "CAD": "$",
      "CDF": "FC",
      "CHF": "Fr",
      "CLP": "$",
      "CNY": "¥",
      "COP": "$",
      "CRC": "₡",
      "CUP": "$",
      "CVE": "$",
      "CZK": "Kč",
      "DJF": "Fdj",
      "DKK": "kr",
      "DOP": "RD$",
      "DZD": "د.ج",
      "EGP": "E£",
      "ERN": "Nfk",
      "ETB": "Br",
      "EUR": "€",
      "FJD": "$",
      "FKP": "£",
      "FOK": "kr",
      "GBP": "£",
      "GEL": "₾",
      "GGP": "£",
      "GHS": "₵",
      "GIP": "£",
      "GMD": "D",
      "GNF": "FG",
      "GTQ": "Q",
      "GYD": "$",
      "HKD": "$",
      "HNL": "L",
      "HRK": "kn",
      "HTG": "G",
      "HUF": "Ft",
      "IDR": "Rp",
      "ILS": "₪",
      "IMP": "£",
      "INR": "₹",
      "IQD": "ع.د",
      "IRR": "﷼",
      "ISK": "kr",
      "JEP": "£",
      "JMD": "J$",
      "JOD": "د.ا",
      "JPY": "¥",
      "KES": "KSh",
      "KGS": "сом",
      "KHR": "៛",
      "KID": "$",
      "KMF": "CF",
      "KRW": "₩",
      "KWD": "د.ك",
      "KYD": "$",
      "KZT": "₸",
      "LAK": "₭",
      "LBP": "ل.ل",
      "LKR": "රු",
      "LRD": "$",
      "LSL": "M",
      "LYD": "ل.د",
      "MAD": "د.م.",
      "MDL": "L",
      "MGA": "Ar",
      "MKD": "ден",
      "MMK": "K",
      "MNT": "₮",
      "MOP": "P",
      "MRU": "UM",
      "MUR": "₨",
      "MVR": "ރ.",
      "MWK": "MK",
      "MXN": "$",
      "MYR": "RM",
      "MZN": "MT",
      "NAD": "$",
      "NGN": "₦",
      "NIO": "C$",
      "NOK": "kr",
      "NPR": "रू",
      "NZD": "$",
      "OMR": "ر.ع.",
      "PAB": "B/.",
      "PEN": "S/.",
      "PGK": "K",
      "PHP": "₱",
      "PKR": "₨",
      "PLN": "zł",
      "PYG": "₲",
      "QAR": "ر.ق",
      "RON": "lei",
      "RSD": "din.",
      "RUB": "₽",
      "RWF": "RF",
      "SAR": "ر.س",
      "SBD": "$",
      "SCR": "₨",
      "SDG": "ج.س.",
      "SEK": "kr",
      "SGD": "$",
      "SHP": "£",
      "SLL": "Le",
      "SOS": "Sh",
      "SRD": "$",
      "SSP": "£",
      "STN": "Db",
      "SYP": "£",
      "SZL": "L",
      "THB": "฿",
      "TJS": "ЅМ",
      "TMT": "T",
      "TND": "د.ت",
      "TOP": "T$",
      "TRY": "₺",
      "TTD": "TT$",
      "TVD": "$",
      "TWD": "NT$",
      "TZS": "Sh",
      "UAH": "₴",
      "UGX": "USh",
      "USD": "$",
      "UYU": "$U",
      "UZS": "UZS",
      "VES": "Bs.",
      "VND": "₫",
      "VUV": "Vt",
      "WST": "WS$",
      "XAF": "FCFA",
      "XCD": "$",
      "XDR": "SDR",
      "XOF": "CFA",
      "XPF": "₣",
      "YER": "﷼",
      "ZAR": "R",
      "ZMW": "ZK",
      "ZWL": "Z$"
  };

  export const managerUserEmails = ["manager@sandboxdc.com", "manager@childtest.com", "eduardo.robles@sihoteles.com"]

  export const updateLocalStorage = (value) => {
    localStorage.setItem("ratesloader", value);
    const event = new Event("ratesloaderUpdate");
    window.dispatchEvent(event); // Trigger the custom event
    };

