/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { AppBar, Box, Toolbar, IconButton, Typography, Menu, Avatar, Tooltip, MenuItem } from "@mui/material";
import { Container, Button } from "reactstrap";
import MenuIcon from "@mui/icons-material/Menu";
import { useHistory, useLocation } from "react-router-dom";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';
import { useAuth0 } from "@auth0/auth0-react";
import _ from "underscore";
import "./Header.scss";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import logo from "../../assets/Images/logo.webp";  // Stagging logo 
import logoLive from "../../assets/Images/logo-00.webp"; // Live Logo
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Snackbar from "@mui/material/Snackbar";
import Alert from '@mui/material/Alert';
import { IdleTimerProvider as IdleTimer } from "react-idle-timer";
import session_out from "../../assets/Images/session-out-img.webp";
import CloseIcon from '@mui/icons-material/Close';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import LoginIcon from '@mui/icons-material/Login';
// import englishFlag from "../../assets/Images/gb-english.svg";
import { CONFIG } from "../../redux/services/config";
import DiscountIcon from '@mui/icons-material/Discount';
import axios from 'axios';
import { loadRatesCalenderLookup } from "../../redux/reducers/rates/ratesCalender";
import { useDispatch } from "react-redux";
import { loadTopbarFilterLookup } from "../../redux/reducers/rates/ratesFilter";
import { loadGetInsightsInitial } from "../../redux/reducers/getInsights/getInsights";
import { EventsString, FREE, False, Manager, True, adminString, horizonDataDays, success } from "../../helpers/constants/variableConstants";
import { loadRateParityLookup } from "../../redux/reducers/rates/rateParity";
import { competitors, events, promoCodes, rates, reviews,about, apiDataFeed, contact, home, index, managerPanel, pricing, user_type, viewShop, welcome, welcome_new } from "../../helpers/constants/routerConstants";

function Header(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const { loginWithPopup, user, isAuthenticated, logout } = useAuth0();
  //Email verifications
  const emailverification = user ? user.email_verified ? user.email_verified : false : false;
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const user_details = JSON.parse(localStorage.getItem("userDetails")) ? JSON.parse(localStorage.getItem("userDetails")) : {};
  // const shop_status = !_.isEmpty(user_details) ? user_details["shopstatus"] : "";
  const [scroll, setScroll] = useState(false);
  // const [shopStatus, setShopStatus] = useState(shop_status);
  const [emailVerificationSnack, setEmailVerificationSnack] = useState(false);
  const idleTimerRef = React.createRef();
  const [timeupAlert, setTimeupAlert] = useState(false);
  const [diaTitle, setDiaTitle] = useState("Session Expire Warning");
  const [countDown, setCountDown] = useState(10);
  const [countdownInterval, setCountdownInterval] = useState();
  const [showing, setShowing] = useState(false);
  const [open, setOpen] = useState(false);
  const languageLabel = window.Weglot.getCurrentLang() ? window.Weglot.getCurrentLang() : localStorage.getItem("wglang");
  const languageName = window.Weglot.getCurrentLang() ? window.Weglot.getLanguageName(languageLabel) : "English";
  // const [languageFlag, setLanguageFlag] = useState({ language: languageName, img: `${languageLabel === "en" ? englishFlag : `https://cdn.weglot.com/flags/rectangle_mat/${languageLabel}.svg`}` });
  const [languageFlag, setLanguageFlag] = useState({ language: languageName });
  const [languageHover, setlanguageHover] = useState(false);
  const [currentPage, setCurrentPage] = useState("");
  const isNewUser = !_.isEmpty(user_details) && user_details["isNewUser"] ? user_details["isNewUser"] : false;
  const iswhiteLabel = !_.isEmpty(user_details) && user_details["iswhiteLabel"] ? user_details["iswhiteLabel"] : false;
  const [urlPath, setUrlPath] = useState(!_.isEmpty(user_details) && user_details["urlPath"] ? user_details["urlPath"] : "")
  // const urlPath = !_.isEmpty(user_details) && user_details["urlPath"] ? user_details["urlPath"] : "";
  const hotel_Names = localStorage.getItem("hotelname") ? localStorage.getItem("hotelname") :
    user_details.refreshdata ? user_details.refreshdata.subject && user_details.refreshdata.subject.length > 0 ? user_details.refreshdata.subject[0].hotelname : "" : "";
  const localhotel = user_details.hotels_comp_venue ? user_details.hotels_comp_venue.filter(data => data.hotelcode === parseInt(user_details.my_hotel_code)) : [];
  const blogurl = `${CONFIG.UiUrl}blogs`;
  const logoPath = CONFIG.IsStaging ? logo: logoLive;
  const headerClass = CONFIG.HeaderClass;
  const managerMail = sessionStorage.getItem("manager");
  useEffect(() => {
    setUrlPath(!_.isEmpty(user_details) && user_details["urlPath"] ? user_details["urlPath"] : "")
  }, [user_details])

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 15);
    });

    // initLanguageAction();
    if (location?.pathname === home) {
      loginWithPopup()
    }
  }, []);

  let currentUrl = window.location.href.split(index);
  let current_page = currentUrl[currentUrl.length - 1];
  if (current_page !== currentPage) {
    setCurrentPage(current_page);
  }

  // let currentUrl = window.location.href;
  // if ("http://localhost:3000/" !== currentUrl && isAuthenticated !== true) {
  //   alert("Thanks, you are all set! Please log in to start using our dashboards.");
  // }

  // console.log(window.location.href)

  // useEffect(() => {
  //   setShopStatus(shop_status);
  // }, [shop_status])

  //Email verification
  useEffect(() => {
    if (isAuthenticated && emailverification !== true) {
      if (currentPage !== "competitors" && currentPage === "") {
        setEmailVerificationSnack(true);
        setOpen(true);
      }
    }
  }, [isAuthenticated]);

  localStorage.setItem("user", user);
  const search = location.search;
  const params = new URLSearchParams(search);
  const code = params.get('code');
  const logoutUser = () => {
    logout({
      returnTo: window.location.origin,
    });
    sessionStorage.removeItem("manager")
    localStorage.removeItem("authenticate");
    localStorage.removeItem("selectedhotelname");
    localStorage.removeItem("authenticater");
    localStorage.removeItem("hotelname");
    localStorage.removeItem("competitors");
    localStorage.removeItem("userDetails");
    localStorage.removeItem("competitorDetails");
    localStorage.removeItem("hotelDetail");
    localStorage.removeItem("hotelNames");
    localStorage.removeItem("packagedetails");
    localStorage.removeItem("holidayDetails");
    localStorage.removeItem("Event_Filter");
    localStorage.removeItem("filterFields");
    localStorage.removeItem("ratestatus");
    sessionStorage.clear();
  }

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  // const handleChange = (event) => {
  //   setLanguage(event.target.value);
  // };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const history = useHistory();
  
  const handleClick = (type) => {
    let navigationType = isNewUser &&
      type !== "myaccount" &&
      type !== "api-data-feed" &&
      type !== "pricing" &&
      type !== "about" &&
      type !== "contact" &&
      type !== "viewshop"
      ? type + "_features" : type;
    history.push(`/${navigationType}`);
    if (type === rates) {
      // || localStorage.getItem("ratestour") === 1
      // || localStorage.getItem("reviewstour") === 1
      // || localStorage.getItem("eventstour") === 1
      if (localStorage.getItem("ratestour") === null || localStorage.getItem("ratestour") === undefined) {
        localStorage.setItem("ratestour", 0);
      }
    } else if (type === reviews) {
      if (localStorage.getItem("reviewstour") === null || localStorage.getItem("reviewstour") === undefined) {
        localStorage.setItem("reviewstour", 0);
      }
    } else if (type === events) {
      if (localStorage.getItem("eventstour") === null || localStorage.getItem("eventstour") === undefined) {
        localStorage.setItem("eventstour", 0);
      }
    }
    if (navigationType === "myaccount") {
      handleCloseUserMenu();
    }
    setAnchorElNav(null);
  }
  const dashboardClick = () => {
    if (window === window.parent) {
      if (isAuthenticated && emailverification === true && user["Ratemetrics/roles"] && user["Ratemetrics/roles"][0] === Manager) {
        if (user_details.isManagerModified && user_details.email === user_details.managerPlan[0].manager_email) {
          history.push(managerPanel);
        } else if (!user_details.isManagerModified) {
          if (user.email !== user_details.email) {
            history.push(welcome);
          } else {
            history.push(managerPanel);
          }
        }
        else {
          history.push(welcome);
        }
      } else if (isAuthenticated && emailverification === true && user_details.shopstatus) {
        history.push(welcome);
      } else if (user_details.plan_type === EventsString) {
        history.push(welcome);
      } else if (isAuthenticated && emailverification === true) {
        history.push(pricing)
      } else if (isAuthenticated && emailverification === true && !user_details.shopstatus) {
        history.push({
          pathname: competitors,
          state: {
            type: FREE,
            packageDetails: [horizonDataDays?.hor30, "8 Competitors", "1 real time refresh / day"],
            competitorsCount: 8,
            days: 30,
          },
        });
      } else {
        history.push(index);
      }
    } else if (window !== window.parent) {
      if (location?.pathname !== competitors) {
        history.push(welcome);
      }
      if (location?.pathname === competitors && user_details.shop_status) {
        history.push(welcome);
      }
    }
  };
  const onActive = () => { };

  const onAction = () => { };

  const handleClose = () => {
    logoutUser();
  };

  const handleContinue = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${user_details["token"]}`,
        "Content-Type": "application/json",

      }
    }
    const data = { "useremail": user_details["email"], "Isadminlogged": false }
    axios.post(`${CONFIG.MonolithicApi}shops/getshopdetails`, data, config).then(async (response) => {
      let shop_status = response["data"] && response["data"][0] && response["data"][0]["IsShop"] === False ? false :
        response["data"] && response["data"][0] && response["data"][0]["IsShop"] === True ? true : "";
      if (shop_status) {
        setTimeupAlert(false);
        setCountDown(10);
        clearInterval(countdownInterval);
        setShowing(!showing);
      } else {
        logoutUser();
      }
    }
    ).catch((error) => {
      console.log("Error at---", error)
    });
  };

  const onIdle = () => {
    let diaTitle;
    if (!timeupAlert) {
      setTimeout(() => {
        let countDown = 10;
        setTimeupAlert(true);
        let countdownInterval = setInterval(() => {
          if (countDown > 0) {
            countDown = countDown - 1;
            diaTitle = countDown > 0 ? "Session Expire Warning" : "Your session has expired";
            setDiaTitle(diaTitle);
            setCountDown(countDown > 0 ? countDown : "");
            setCountdownInterval(countdownInterval);
          } else {
            setDiaTitle(diaTitle);
            clearInterval(countdownInterval);
          }
        }, 1000);
      }, 1000);
      setShowing(true);
    }
  };

  const handleLogin = () => {
    history.push(index);
    loginWithPopup();
  }

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handclickLanguage = (e, language, img) => {
    setLanguageFlag({ language: window.Weglot.getLanguageName(language), img: img });
    window.Weglot.switchTo(e.target.value);
    setlanguageHover(false);
    props.sendToParent({ language: window.Weglot.getLanguageName(language), img: img })
  };

  const languageDropDown = () => {
    setlanguageHover(true);
  }

  const adminPanel = () => {
    setAnchorElUser(null);
    history.push(viewShop);
  }

  const adminPromocodes = () => {
    setAnchorElUser(null);
    history.push(promoCodes);
  }

  const managersPanel = () => {
    setAnchorElUser(null);
    if (managerMail) {
      history.push({
        pathname: managerPanel,
        state: { user: managerMail }
      });
    } else {
      history.push(managerPanel);
    }
  }

  let availableLanguages = window.Weglot.options.languages ? window.Weglot.options.languages.map(function (language) {
    return language.language_to;
  }).concat(window.Weglot.options.language_from).sort((a, b) => a.localeCompare(b)) : [];

  const logoutUserByAdmin = (manager) => {
    setAnchorElUser(null);
    let user_token = { token: "" };
    user_token["token"] = user_details.token;
    user_token["email"] = user.email;
    localStorage.setItem("userDetails", JSON.stringify(user_token))
    dispatch(loadRatesCalenderLookup());
    dispatch(loadTopbarFilterLookup());
    dispatch(loadGetInsightsInitial())
    dispatch(loadRateParityLookup())
    if (manager?.isloged) {
      history.push({
        pathname: user_type,
        state: { adminLogout: manager?.isloged ? 3 : 0 }
      })
      sessionStorage.removeItem("manager")
    } else if (user_details.email !== user.email && !manager?.isloged && managerMail) {
      history.push({
        pathname: managerPanel,
        state: { user: managerMail }
      });
    } else {
      history.push({
        pathname: user_type,
        state: { adminLogout: !manager?.isloged && user_details.plan_type === EventsString ? 2 : 0 }
        // state: {
        //   email_user: user.email
        // }
      })
      sessionStorage.removeItem("manager")
    }
    localStorage.removeItem("authenticate");
    localStorage.removeItem("selectedhotelname");
    localStorage.removeItem("authenticater");
    localStorage.removeItem("hotelname");
    localStorage.removeItem("competitors");
    localStorage.removeItem("competitorDetails");
    localStorage.removeItem("hotelDetail");
    localStorage.removeItem("hotelNames");
    localStorage.removeItem("packagedetails");
    localStorage.removeItem("holidayDetails");
    localStorage.removeItem("Event_Filter");
    localStorage.removeItem("filterFields");
    localStorage.removeItem("ratestatus");
    sessionStorage.setItem("evFiltered",true)

  };

  useEffect(() => {
    if(user && user_details?.userstatus === "Inactive") {
        if( user_details?.email !== user?.email) {
            logoutUserByAdmin({ isloged: false, email: user_details?.email })
        } else {
            logoutUser() 
        }
    }
}, [user_details.userstatus, user])

  return (
    <div className={emailVerificationSnack === true ? "backgroundhide" : ""}>
      {showing && isAuthenticated ? (
        <div >
          <Dialog
            open={showing}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onBackdropClick="false"
            disableEscapeKeyDown
            className="time-out-popup"
          >
            <Button className="time-out-close-btn" onClick={handleClose}>+</Button>
            <DialogTitle id="alert-dialog-title" className="time-out-title-svg">
              <img loading="lazy" src={session_out} as="image" alt="Timeout" />
            </DialogTitle>
            <DialogTitle id="alert-dialog-title" className="time-out-title">
              {diaTitle}
            </DialogTitle>

            <DialogContent>
              {countDown === 0 ? (
                <DialogContentText id="alert-dialog-description" className="time-out-desc">
                  {"You will be redirected to login page"}
                </DialogContentText>
              ) : (
                ""
              )}
              {countDown > 0 ? (
                <DialogContentText id="alert-dialog-description" className="time-out-desc">
                  <span>{`Your session will expire in ${countDown} seconds.`}</span><br />
                  <span>{`Please click continue to stay connected with us`}</span>
                </DialogContentText>
              ) : (
                ""
              )}
            </DialogContent>
            <DialogActions className="continue-ok-btn">
              {countDown === 0 ? (
                <Button
                  onClick={() => handleClose()}
                  className="login-2"
                >
                  OK
                </Button>
              ) : (
                ""
              )}
              {countDown > 0 ? (
                <Button
                  onClick={() => handleContinue()}
                  color="primary"
                  className="login-2"
                >
                  Continue
                </Button>
              ) : (
                ""
              )}
            </DialogActions>
          </Dialog>
        </div>
      ) : null}
      {emailVerificationSnack === true ?
        <Snackbar open={open} onClose={handleCloseAlert} anchorOrigin={{ vertical: "top", horizontal: "center" }} className=" bg-green emailconfirmation adminmessage">
          <Alert severity={success}>
            {code === success ?
              <><div className="adminicon"><div className="alert-txt"><TaskAltIcon />Successfully</div><div className="closeicon" onClick={logoutUser}><CloseIcon /></div></div><p>Thanks, you are all set! <br />Please log in to start using our dashboards</p> </>
              : <><div className="adminicon"><div className="alert-txt"><TaskAltIcon />Successfully</div><div className="closeicon" onClick={logoutUser}><CloseIcon /></div></div><p>You are almost there! <br />We have sent you a link to verify your email address.</p></>}
            <hr />
            <div className="emailconfirmation-btn3" >
              <Button onClick={logoutUser}>Ok</Button>
            </div>
          </Alert>
        </Snackbar>
        : code === success ? <Snackbar open onClose={handleCloseAlert} anchorOrigin={{ vertical: "top", horizontal: "center" }} className="bg-green emailconfirmation adminmessage">
          <Alert severity={success}>
            <><div className="adminicon"><div className="alert-txt"><TaskAltIcon />Successfully</div><div className="closeicon" onClick={logoutUser}><CloseIcon /></div></div><p>Thanks, you are all set! <br />Please log in to start using our dashboards</p></>
            <hr />
            <div className="emailconfirmation-btn3" >
              <Button onClick={logoutUser}>Ok</Button>
            </div>
          </Alert>
        </Snackbar> : ""}
      <IdleTimer
        ref={idleTimerRef}
        onActive={onActive}
        onIdle={onIdle}
        onAction={onAction}
        debounce={250}
        timeout={3600000}
      />
      <div className={user_details?.iswhiteLabel && window !== window.parent ? "stagging-header" : headerClass}>
        {/* live-header (or) stagging-header*/}
        <AppBar className={scroll ? "topbar-style sticky-header " : "topbar-style position-header "} >
          <Container maxwidth="xl" className="header-1">
            <Toolbar disableGutters className={user_details?.iswhiteLabel && window !== window.parent ? "menu-bar-ht mobile-view-manager" : "menu-bar-ht"}>
              {/* Desktop logo */}
              <Typography noWrap onClick={() => dashboardClick()} className="logo-text"
                sx={{
                  mr: 2,
                  display: { xs: 'none', md: 'flex' },
                }}>
                <img rel="preload" loading="lazy" as="image" src={urlPath !== "" && urlPath !== null ? `${CONFIG.MonolithicApi}images/${urlPath}` : logoPath} className="logo-1" width="205" height="63" alt="RateMetrics" />
              </Typography>
              {/* Desktop logo end*/}

              {/* MOBILE MENU */}
              <div className={user_details ? "menu-btn-logo no-translate" : "menu-btn-logo"}>
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                  <IconButton
                    size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu}
                    color="inherit" className="menusvg" >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                      display: { xs: 'block', md: 'none' },
                    }} >

                    {user_details.plan_type !== EventsString ? <MenuItem onClick={() => handleClick("rates")} className={location?.pathname === rates ? "activePage" : ""}>
                      <Typography textAlign="center">RATES</Typography>
                    </MenuItem> : ""}
                    {user_details.plan_type !== EventsString ? <MenuItem onClick={() => handleClick("reviews")} className={location?.pathname === reviews ? "activePage" : ""}>
                      <Typography textAlign="center">REVIEWS</Typography>
                    </MenuItem> : ""}
                    {user_details?.isEventPage?<MenuItem onClick={() => handleClick("events")} className={location?.pathname === events ? "activePage" : ""}>
                      <Typography textAlign="center">EVENTS</Typography>
                    </MenuItem>:""}
                    {/* {user_details.plan_type !== EventsString && user_details.shopstatus ? <MenuItem onClick={() => handleClick("compare")} className={location?.pathname === compare ? "activePage" : ""}>
                      <Typography textAlign="center">Rates Vs Events</Typography>
                    </MenuItem> : ""} */}
                    {!iswhiteLabel ? <MenuItem onClick={() => handleClick("api-data-feed")} className={location?.pathname === apiDataFeed ? "activePage" : ""}>
                      <Typography textAlign="center">API/DATA FEED</Typography>
                    </MenuItem> : ""}
                    {!iswhiteLabel ? <MenuItem onClick={() => handleClick("pricing")} className={location?.pathname === pricing ? "activePage" : ""}>
                      <Typography textAlign="center">{!isAuthenticated ? "PRICING" : "PLANS"}</Typography>
                    </MenuItem> : ""}
                    {!iswhiteLabel ? <MenuItem onClick={() => handleClick("about")} className={location?.pathname === about ? "activePage" : ""}>
                      <Typography textAlign="center">ABOUT</Typography>
                    </MenuItem> : ""}
                    {!iswhiteLabel ? <MenuItem onClick={() => handleClick("contact")} className={location?.pathname === contact ? "activePage" : ""}>
                      <Typography textAlign="center">CONTACT</Typography>
                    </MenuItem> : ""}
                    {/* {!iswhiteLabel ? <MenuItem><a href={blogurl} className="mobilemenu" target="_blank" rel="noreferrer" alt="Blog" >
                      BLOG
                    </a></MenuItem> : ""} */}
                  </Menu>
                </Box>

                <Typography noWrap onClick={() => dashboardClick()} className="logo-text1"
                  sx={{
                    mr: 2,
                    display: { xs: 'flex', md: 'none' },
                    flexGrow: 1,
                  }} >
                  <img loading="lazy" as="image" rel="preload" src={urlPath !== "" && urlPath !== null ? `${CONFIG.MonolithicApi}images/${urlPath}` : logoPath} className="logo" width="300" height="122" alt="RateMetrics" />
                </Typography>
              </div>
              {/* Mobile logo end */}

              {/* Desktop menu */}
              {
                window === window.parent &&
                <Box className="menubox" sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                  <span className="dropdown">
                    <Button onClick={() => dashboardClick()} className={location?.pathname === rates ? `activePage dropbtn no-translate ${user_details?.iswhiteLabel ? "black-text" : ""} ` : `un dropbtn no-translate ${user_details?.iswhiteLabel ? "black-text" : ""}`} >
                      {!isAuthenticated || emailverification !== true ?
                        window === window.parent ? <i>Features</i> : <i>My Dashboards</i>
                        :
                        <i>My Dashboards</i>
                      }
                      <span><KeyboardArrowDownIcon /></span>
                    </Button>
                    {location?.pathname === welcome_new ?
                      <></>
                      :
                      <div className={!isAuthenticated || emailverification !== true ? "dropdown-content" : "extented-width dropdown-content"}>
                        {user_details.plan_type !== EventsString ? <Button onClick={() => handleClick("rates")} className={location?.pathname === rates ? "activePage1" : ""}>
                          <span>RATES</span>
                        </Button> : <></>}
                        {user_details.plan_type !== EventsString ? <Button onClick={() => handleClick("reviews")} className={location?.pathname === reviews ? "activePage1" : ""}>
                          <span>REVIEWS</span>
                        </Button> : <></>}
                       {user_details?.isEventPage || !isAuthenticated ? <Button onClick={() => handleClick("events")} className={location?.pathname === events ? "activePage1" : ""}>
                          <span>EVENTS</span>
                        </Button>:""}
                        {/* {user_details.plan_type !== EventsString && user_details.shopstatus ? <Button onClick={() => handleClick("comparision")} className={location?.pathname === compare ? "activePage1" : ""}>
                      <span>Rates Vs Events</span>
                    </Button> : <></>} */}
                      </div>
                    }
                  </span>
                  {!iswhiteLabel ? <Button onClick={() => handleClick("api-data-feed")} className={location?.pathname === apiDataFeed ? "activePage" : "un"}>
                    <span className="">API</span>
                  </Button> : ""}
                  {!iswhiteLabel ? <Button onClick={() => handleClick("pricing")} className={location?.pathname === pricing ? "activePage" : "un"}>
                    <span className="">{!isAuthenticated || emailverification !== true ? "Pricing" : "Plans"}</span>
                  </Button> : ""}
                  {!iswhiteLabel ? <Button onClick={() => handleClick("about")} className={location?.pathname === about ? "activePage" : "un"}>
                    <span className="">ABOUT</span>
                  </Button> : ""}
                  {!iswhiteLabel ? <Button onClick={() => handleClick("contact")} className={location?.pathname === contact ? "activePage" : "un"}>
                    <span className="">CONTACT</span>
                  </Button> : ""}
                  {/* {!iswhiteLabel ? <a href={blogurl} state={"blogs"} target="_blank" rel="noreferrer" alt="Blog" className="un">
                    <span className="">Blog</span>
                  </a> : ""} */}
                </Box>
              }

              {/* for iframe users */}
              {
                window !== window.parent && user_details.shopstatus &&

                <Box className="menubox" sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                  <span className="dropdown">
                    <Button onClick={() => dashboardClick()} className={location?.pathname === rates ? `activePage dropbtn no-translate ${user_details?.iswhiteLabel ? "black-text" : ""}` : `un dropbtn no-translate ${user_details?.iswhiteLabel ? "black-text" : ""}`} >
                      {!isAuthenticated || emailverification !== true ?
                        window === window.parent ? <i>Features</i> : <i>My Dashboards</i>
                        :
                        <i>My Dashboards</i>
                      }
                      <span><KeyboardArrowDownIcon /></span>
                    </Button>
                    {location?.pathname === welcome_new ?
                      <></>
                      :
                      <div className={!isAuthenticated || emailverification !== true ? "dropdown-content" : "extented-width dropdown-content"}>
                        {user_details.plan_type !== EventsString ? <Button onClick={() => handleClick("rates")} className={location?.pathname === rates ? "activePage1" : ""}>
                          <span>RATES</span>
                        </Button> : <></>}
                        {user_details.plan_type !== EventsString ? <Button onClick={() => handleClick("reviews")} className={location?.pathname === reviews ? "activePage1" : ""}>
                          <span>REVIEWS</span>
                        </Button> : <></>}
                        {user_details?.isEventPage?<Button onClick={() => handleClick("events")} className={location?.pathname === events ? "activePage1" : ""}>
                          <span>EVENTS</span>
                        </Button>:""}
                        {/* {user_details.plan_type !== EventsString && user_details.shopstatus ? <Button onClick={() => handleClick("comparision")} className={location?.pathname === compare ? "activePage1" : ""}>
                      <span>Rates Vs Events</span>
                    </Button> : <></>} */}
                      </div>
                    }
                  </span>
                  {!iswhiteLabel ? <Button onClick={() => handleClick("api-data-feed")} className={location?.pathname === apiDataFeed ? "activePage" : "un"}>
                    <span className="">API</span>
                  </Button> : ""}
                  {!iswhiteLabel ? <Button onClick={() => handleClick("pricing")} className={location?.pathname === pricing ? "activePage" : "un"}>
                    <span className="">{!isAuthenticated || emailverification !== true ? "Pricing" : "Plans"}</span>
                  </Button> : ""}
                  {!iswhiteLabel ? <Button onClick={() => handleClick("about")} className={location?.pathname === about ? "activePage" : "un"}>
                    <span className="">ABOUT</span>
                  </Button> : ""}
                  {!iswhiteLabel ? <Button onClick={() => handleClick("contact")} className={location?.pathname === contact ? "activePage" : "un"}>
                    <span className="">CONTACT</span>
                  </Button> : ""}
                  {/* {!iswhiteLabel ? <a href={blogurl} state={"blogs"} target="_blank" rel="noreferrer" alt="Blog" className="un">
                    <span className="">Blog</span>
                  </a> : ""} */}
                </Box>
              }

              {window===window.parent?<div>
                <Box sx={{ flexGrow: 0 }} className="menu-lang-avatr d-flex">
                  <div className="languages no-translate ">
                    <div className="dropdown">
                     {!isAuthenticated && <Button className="dropbtn" onMouseOver={languageDropDown} >
                        <div className="lang-details">
                          {/* <img loading="lazy" src={languageFlag.img} width="30" height="20" alt="" /> */}
                          <span>{languageFlag.language}</span>
                        </div>
                        <div className="arrowimg"><KeyboardArrowDownIcon /></div>
                      </Button>}
                      {languageHover ? <div className="dropdown-content">
                        {availableLanguages.filter(data => data !== languageLabel).map((lan, index) => (
                          // <Button key={index} value={lan} onClick={(e) => handclickLanguage(e, lan, `${lan === "en" ? englishFlag : `https://cdn.weglot.com/flags/rectangle_mat/${lan}.svg`}`)}>
                          <Button key={index} value={lan} onClick={(e) => handclickLanguage(e, lan)}>
                            {/* <img key={index} width="30" height="20" src={`${lan === "en" ? englishFlag : `https://cdn.weglot.com/flags/rectangle_mat/${lan}.svg`}`} alt="Español flag" role="none" class="wg-flag" /> */}
                            <>{window.Weglot.getLanguageName(lan)}</>
                          </Button>
                        ))}

                      </div> : ""}
                    </div>
                  </div>

                  {/*--Iframe header--*/}
                  {!user && user_details.iswhiteLabel && location?.pathname !== index &&
                                      <>
                      <Tooltip>
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                          <Avatar alt="Remy Sharp" src={`https://i1.wp.com/cdn.auth0.com/avatars/${user_details.email[0] + user_details.email[1]}.png?ssl=1`} />
                        </IconButton>
                      </Tooltip>
                      <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        className="header-dropdown"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu} >
                        {
                          isNewUser ? "" :
                            <MenuItem onClick={() => handleClick("myaccount")}>
                              <Typography textAlign="center" className="myaccountname"><PersonIcon />My Account</Typography>
                            </MenuItem>
                        }
                      </Menu>
                    </>}

                  {!isAuthenticated || emailverification !== true ?
                    <>{window === window.parent ?
                      <Button
                        id="combo"
                        className="m-t-4 qsLoginBtn" onClick={() => handleLogin()}  >
                        <span id="desc-login"><LoginIcon />Log in</span> <span id="mob-login"><PowerSettingsNewIcon /></span>
                      </Button> : ""}</>
                    :
                    <>
                      <Tooltip>
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                          <Avatar alt="Remy Sharp" src={user.picture} />
                        </IconButton>
                      </Tooltip>
                      <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        className="header-dropdown"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu} >
                        {isNewUser ? "" :
                          <MenuItem onClick={() => handleClick("myaccount")}>
                            {user["Ratemetrics/roles"] ? user["Ratemetrics/roles"][0] === Manager ?
                              <Typography textAlign="center" className="myaccountname"><PersonIcon /> {localhotel[0] && localhotel[0].hotelname ? localhotel[0].hotelname : hotel_Names && hotel_Names !== "" && hotel_Names !== null ? <>{hotel_Names}</> : "My Account"}</Typography>
                              : <Typography textAlign="center" className="myaccountname"><PersonIcon />My Account</Typography>
                              : <Typography textAlign="center" className="myaccountname"><PersonIcon />My Account</Typography>}
                          </MenuItem>
                        }
                        {user["Ratemetrics/roles"] ? user["Ratemetrics/roles"][0] === adminString ?
                          <MenuItem onClick={adminPanel} className="user-list-icon">
                            <Typography textAlign="center"><GroupsIcon />Admin Panel</Typography>
                          </MenuItem> : "" : ""}

                        {user["Ratemetrics/roles"] ? user["Ratemetrics/roles"][0] ===  adminString?
                          <MenuItem onClick={adminPromocodes} className="user-list-icon">
                            <Typography textAlign="center"><DiscountIcon />Manage Promo Codes</Typography>
                          </MenuItem> : "" : ""}
                        {user["Ratemetrics/roles"] || !!managerMail ? user["Ratemetrics/roles"][0] === Manager || !!managerMail ?
                          <MenuItem onClick={managersPanel} className="user-list-icon">
                            <Typography textAlign="center"><GroupsIcon />Manager Panel</Typography>
                          </MenuItem> : "" : ""}
                        {managerMail &&
                          <MenuItem onClick={() => logoutUserByAdmin({ isloged: true, email: managerMail })}>
                            <Typography textAlign="center"><PowerSettingsNewIcon />Logout Manager</Typography>
                          </MenuItem>
                        }
                        {user_details.email !== user.email ?
                          <MenuItem onClick={() => logoutUserByAdmin({ isloged: false, email: user_details.email })}>
                            <Typography textAlign="center"><PowerSettingsNewIcon />Logout User</Typography>
                          </MenuItem> : ""}
                        <MenuItem onClick={logoutUser}>
                          <Typography textAlign="center"><PowerSettingsNewIcon />Logout</Typography>
                        </MenuItem>
                      </Menu>
                    </>
                  }
                </Box>
              </div>:""}
            </Toolbar>
          </Container>
        </AppBar>
      </div>
      <div className={scroll ? "empty-ht" : "empty-ht-1"}></div>
    </div>
  );
}

export default Header;