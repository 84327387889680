/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Route, useHistory, useLocation } from "react-router-dom";
import DashboardRoute from "../dashboardRoute";
import { TourProvider, components } from '@reactour/tour';
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { eventsonlysteps, reviewssteps, steps as compsteps, True, False, EventsString } from '../../helpers/constants/variableConstants';
import _ from "underscore";
import { loadApplySkipTour } from "../../redux/reducers/skiptour/skipTour";
import { useDispatch } from "react-redux";
import { competitors, events, rates, reviews } from "../../helpers/constants/routerConstants";
import { useAuth0 } from "@auth0/auth0-react";
// import { managerUserEmails } from "../../helpers/constants/variableConstants";

const DashboardLayout = ({ render, ...rest }) => {

  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { user, isAuthenticated } = useAuth0();


  // const ispath = rest.path;
  const path = rest.path
  const userDetails = JSON.parse(localStorage.getItem("userDetails")) ? JSON.parse(localStorage.getItem("userDetails")) : {};
  // const managerEmailAddress = sessionStorage.getItem("manageremail") !== null ? sessionStorage.getItem("manageremail") : {};
  const skipTourStatus = !_.isEmpty(userDetails) && userDetails["skipTourStatus"] ? userDetails["skipTourStatus"] : "";
  const [ratescontentstep, setRatesContentSteps] = useState(false);
  const [reviewscontentstep, setReviewsContentSteps] = useState(false);
  const [eventscontentstep, setEventsContentSteps] = useState(false);
  const [compcontentstep, setCompContentSteps] = useState(false);
  // const filter_fields = JSON.parse(localStorage.getItem("filterFields")) ? JSON.parse(localStorage.getItem("filterFields")) : {};
  // let ratessteps = [otastep, losstep, filter_fields.maxoccupancy === 0 ? {} : gueststep , filter_fields.mealinclusiontype  === "" ? {} : mealpalnstep, cancelationstep, filter_fields.roomtype === "" ? {} : roomtypestep, ratestep, ratescalenderstep, ratesevalutionstep, rateslistviewstep, rateparitystep, downloadviewstep]
  // var ratesFinalSteps = ratessteps.filter(value => JSON.stringify(value) !== '{}');
  const sliceEventSteps = eventsonlysteps.slice(1);
  useEffect(() => {
    if (isAuthenticated && user.email_verified && userDetails.shopstatus && !userDetails?.isEventPage && (location?.pathname === "/single_event_page" || location?.pathname === "/single_day_events" || location?.pathname === "/events")) {
      history.goBack();
    } else {
      if (userDetails.isManagerModified === true) {
        const primaryColor = userDetails.managerPlan ? userDetails.managerPlan[0].primary_color : "";
        const secondaryColor = userDetails.managerPlan ? userDetails.managerPlan[0].secondary_color : "";
        // const high_to_low = `linear-gradient(to right,rgba(0,0,0,0.1),rgba(255,255,255,0.5)), ${secondaryColor}`;
        // const low_to_high = `linear-gradient(to right,rgba(255,255,255,0.5),rgba(0,0,0,0.1)), ${secondaryColor}`;
        const high_to_low = secondaryColor;
        const low_to_high = secondaryColor;
        if (primaryColor !== "" && secondaryColor !== "") {
          document.documentElement.style.setProperty('--first-color', primaryColor);
          document.documentElement.style.setProperty('--second-color', secondaryColor);
          document.documentElement.style.setProperty('--high-to-low', high_to_low);
          document.documentElement.style.setProperty('--low-to-high', low_to_high);
        }
      } else {
        const orange = "#FF914E";
        const blue = "#07748F";
        if(userDetails.iswhiteLabel) {
          const hight_low = blue;
          const low_high = blue;
          document.documentElement.style.setProperty('--high-to-low', hight_low);
          document.documentElement.style.setProperty('--low-to-high', low_high);
        } else {
          const hight_low = "linear-gradient(to right, #07748F 0%, #72B0C0 100%)";
          const low_high = "linear-gradient(90deg, #72B0C0 0, #07748F)";
          document.documentElement.style.setProperty('--high-to-low', hight_low);
          document.documentElement.style.setProperty('--low-to-high', low_high);
        }
        document.documentElement.style.setProperty('--first-color', orange);
        document.documentElement.style.setProperty('--second-color', blue);
        
      }
    }
  }, [userDetails])

  function Badge({ child, totalSteps, currentStep }) {
    const children = currentStep + 1;
    if (children === totalSteps && path === rates) {
      setRatesContentSteps(true);
      setReviewsContentSteps(false);
      setEventsContentSteps(false);
      setCompContentSteps(false);
    } else if (children === reviewssteps.length && path === reviews) {
      setReviewsContentSteps(true);
      setRatesContentSteps(false);
      setEventsContentSteps(false);
      setCompContentSteps(false);
    } else if (children === sliceEventSteps.length && path === events && userDetails.plan_type !== EventsString) {
      setEventsContentSteps(true);
      setRatesContentSteps(false);
      setReviewsContentSteps(false);
      setCompContentSteps(false);
    }
    else if (children === eventsonlysteps.length && path === events && userDetails.plan_type === EventsString) {
      setEventsContentSteps(true);
      setRatesContentSteps(false);
      setReviewsContentSteps(false);
      setCompContentSteps(false);
    }
    else if (children === compsteps.length && path === competitors) {
      setCompContentSteps(true);
      setRatesContentSteps(false);
      setReviewsContentSteps(false);
      setEventsContentSteps(false);
    } else {
      setRatesContentSteps(false);
      setReviewsContentSteps(false);
      setEventsContentSteps(false);
      setCompContentSteps(false);
    }
    return (
      <components.Badge
        styles={{ badge: (base) => ({ ...base, }) }}
      > {path === rates ?
        <div className="no-translate">{children}/{totalSteps} </div>
        :
        path === reviews ?
          <div className="no-translate">{children}/{reviewssteps.length} </div>
          :
          path === events && userDetails.plan_type !== EventsString ?
            <div className="no-translate">{children}/{sliceEventSteps.length} </div>
            :
            path === events && userDetails.plan_type === EventsString ?
              <div className="no-translate">{children}/{eventsonlysteps.length} </div>
              :
              <div className="no-translate">{children}/{compsteps.length} </div>
        }

      </components.Badge>
    )
  }

  const styles = {
    maskWrapper: (base, { x, y }) => ({
      ...base,
      x: x + 90,
      y: y + 90
    }),
    position: (base, { x, y }) => ({
      x: x,
      y: y
    }),
    highlightedArea: (base, { x, y }) => ({
      ...base,
      x: x + 10,
      y: y + 10,
      color: "yellow"
    }),
    badge: (base) => ({ ...base, color: 'blue' }),
    inViewThreshold: (base, { x, y }) => ({
      ...base,
      x: x,
      y: y
    }),
  }

  const closeskiptour = ({ setCurrentStep, currentStep, steps, setIsOpen }) => {
    if (path === competitors && skipTourStatus !== "" && skipTourStatus?.isCompetitor === False) {
      let userDetails = JSON.parse(localStorage.getItem("userDetails")) ? JSON.parse(localStorage.getItem("userDetails")) : {};
      const skipTourStatus = userDetails["skipTourStatus"];
      const updateskipstatus = { ...skipTourStatus, isCompetitor: True }
      userDetails["skipTourStatus"] = updateskipstatus
      localStorage.setItem("userDetails", JSON.stringify(userDetails));
      const data = {
        "useremail": userDetails.email,
        "skiptourStatus": updateskipstatus,
      }
      dispatch(loadApplySkipTour({ token: userDetails.token, data: data }));
    } else if (path === rates && skipTourStatus !== "" && skipTourStatus.isRates === False) {
      let userDetails = JSON.parse(localStorage.getItem("userDetails")) ? JSON.parse(localStorage.getItem("userDetails")) : {};
      const skipTourStatus = userDetails["skipTourStatus"];
      const updateskipstatus = { ...skipTourStatus, isRates: True, isCompetitor: True }
      userDetails["skipTourStatus"] = updateskipstatus
      localStorage.setItem("userDetails", JSON.stringify(userDetails));
      const data = {
        "useremail": userDetails.email,
        "skiptourStatus": updateskipstatus,
      }
      dispatch(loadApplySkipTour({ token: userDetails.token, data: data }));
    } else if (path === reviews && skipTourStatus !== "" && skipTourStatus.isReviews === False) {
      let userDetails = JSON.parse(localStorage.getItem("userDetails")) ? JSON.parse(localStorage.getItem("userDetails")) : {};
      const skipTourStatus = userDetails["skipTourStatus"];
      const updateskipstatus = { ...skipTourStatus, isReviews: True, isCompetitor: True }
      userDetails["skipTourStatus"] = updateskipstatus
      localStorage.setItem("userDetails", JSON.stringify(userDetails));
      const data = {
        "useremail": userDetails.email,
        "skiptourStatus": updateskipstatus,
      }
      dispatch(loadApplySkipTour({ token: userDetails.token, data: data }));
    } else if (path === events && skipTourStatus !== "" && skipTourStatus.isEvents === False) {
      let userDetails = JSON.parse(localStorage.getItem("userDetails")) ? JSON.parse(localStorage.getItem("userDetails")) : {};
      const skipTourStatus = userDetails["skipTourStatus"];
      const updateskipstatus = { ...skipTourStatus, isEvents: True, isCompetitor: True }
      userDetails["skipTourStatus"] = updateskipstatus
      localStorage.setItem("userDetails", JSON.stringify(userDetails));
      const data = {
        "useremail": userDetails.email,
        "skiptourStatus": updateskipstatus,
      }
      dispatch(loadApplySkipTour({ token: userDetails.token, data: data }));
    }
    setIsOpen(false);
  }

  const maskclicknotclose = ({ setCurrentStep, currentStep, steps, setIsOpen }) => {
    if (setIsOpen) {
      setIsOpen(true);
    }
  }

  return (
    <div className={userDetails?.iswhiteLabel && window !== window.parent ? "background-white skiptour-manager" : "background-white"}>
      <Route
        {...rest}
        render={(matchProps) => (
          <TourProvider styles={styles}
            onTransition={styles}
            className={ratescontentstep === true ? "last-skip skip-tour" : reviewscontentstep === true ? "last-skip skip-tour" : eventscontentstep === true ? "last-skip skip-tour" : compcontentstep === true ? "last-skip skip-tour" : "skip-tour"}
            maskClassName="mask-tour"
            // components={{ Badge }}
            badgeContent={(children, tot, currentStep) => Badge(children, tot, currentStep)}
            highlightedMaskClassName="highlight-mask"
            onClickHighlighted={(e) => {
              e.stopPropagation()
              console.log('No interaction')
            }}
            disableFocusLock={true}
            onClickClose={({ setCurrentStep, currentStep, steps, setIsOpen }) => { closeskiptour({ setCurrentStep, currentStep, steps, setIsOpen }) }}
            onClickMask={({ setCurrentStep, currentStep, steps, setIsOpen }) => { maskclicknotclose({ setCurrentStep, currentStep, steps, setIsOpen }) }}
            disableInteraction={true}
            afterOpen={disableBody}
            showDots={false}
            beforeClose={enableBody}>
            <DashboardRoute>{render(matchProps)}</DashboardRoute>
          </TourProvider>
        )}
      />
    </div>
  );
};

export default DashboardLayout;

