import React, { useState, useRef, useEffect, useMemo } from "react";
import Header from "../core/header/Header";
import Footer from "../core/footer/Footer";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
// import { managerUserEmails } from "../helpers/constants/variableConstants";
import { fullPageView } from "../helpers/components/fullpageview/fullPageView";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { IconButton } from "@mui/material";
import { Container, Row, Col } from "reactstrap";
import { updateLocalStorage } from "../helpers/constants/variableConstants";

const DashboardRoute = ({ children }) => {
  const tableRef = useRef();
  const languageLabel = window.Weglot.getCurrentLang() ? window.Weglot.getCurrentLang() : localStorage.getItem("wglang");
  const languageName = window.Weglot.getCurrentLang() ? window.Weglot.getLanguageName(languageLabel) : "English";
  // const managerEmailAddress = sessionStorage.getItem("manageremail") !== null ? sessionStorage.getItem("manageremail") : {};
  const rateLoader =  localStorage.getItem("ratesloader");
  const [data, setData] = useState(false)
  // const [loaderRates, setLoaderRates] = useState(false)
  const location = useLocation();
  const { user, isAuthenticated } = useAuth0();
  const [isParentData, setIsParentData] = useState({ language: languageName });
  const userDetails = JSON.parse(localStorage.getItem("userDetails")) || {};
  const filter_fields = JSON.parse(localStorage.getItem("filterFields")) ? JSON.parse(localStorage.getItem("filterFields")) : {};

  const [fullScreenEnabled, setFullScreenEnabled] = useState(false);
  const [mobileViewEnabled, setMobileViewEnabled] = useState(false);
  // const history = useHistory();

  const width = window.innerWidth;
  const checkLocalStorage = () => {
    const reload = localStorage.getItem("ratesloader");
    setData(reload === "true");
  };

  useEffect(()=>{
      return () => {
        updateLocalStorage(false)
      };
  },[])

  useEffect(() => {
    // Initial check on component mount
    checkLocalStorage();
    
    // Custom event listener for localStorage changes within the same tab
    const handleStorageChange = () => {
    checkLocalStorage();
    };
    
    // Listen for `storage` events in the same tab (custom event trigger)
    window.addEventListener("ratesloaderUpdate", handleStorageChange);
    
    return () => {
    window.removeEventListener("ratesloaderUpdate", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (width <= 280) {
      setMobileViewEnabled(true)
      } else if (width > 280 && width <= 1024) {
        setMobileViewEnabled(true)
      } else {
        setMobileViewEnabled(false)
      }
  }, [width]);

  return (
    <div className={fullScreenEnabled === true && userDetails?.iswhiteLabel && window !== window.parent ? " page-het overflow-auto" : "page-het"} ref={tableRef}>
      <div className={userDetails?.iswhiteLabel && window !== window.parent && mobileViewEnabled === false ? "reducefullscreenwidth" : ""}>
        {userDetails?.iswhiteLabel && window !== window.parent && mobileViewEnabled === false ?
        <>
        {data ? 
          <div className="background-white-cs mob-center-filter positionavoid ">
            <Container className="p-7-tb blue-bannercontainer">
              <Row className="">
                <Col md={12} className="p-0">
                  <Col md={4} className="p-0">
                  </Col>
                  <Col md={4} className="p-0">
                  </Col>
                  <Col md={4} className={userDetails?.iswhiteLabel && window !== window.parent && mobileViewEnabled === false ? "p-0 text-right" : "d-flex refresh-manual w-140"}>
                    {userDetails?.iswhiteLabel && window !== window.parent && mobileViewEnabled === false ?
                      <IconButton onClick={() => fullPageView(tableRef, setFullScreenEnabled)} className="icon-fullscreen" title="Full Screen View">
                        <FullscreenIcon />
                      </IconButton>
                      : ""}
                  </Col>
                </Col>
              </Row>
            </Container>
          </div>
          :""}
        </>
          : ""}
        {(isAuthenticated && user.email_verified && userDetails.shopstatus && !userDetails?.isEventPage && (location?.pathname === "/single_event_page" || location?.pathname === "/single_day_events" || location?.pathname === "/events")) ? "" :
          <>
            {userDetails?.iswhiteLabel && window !== window.parent && mobileViewEnabled === false ? "" : 
            <Header toChild={isParentData} sendToParent={setIsParentData} />
            }
            {children}
            <Footer toprops={isParentData} />
          </>
        }
      </div>
    </div>
  );
};

export default DashboardRoute;
